import {Component, ComponentFactoryResolver, OnInit, ViewEncapsulation} from '@angular/core';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {OffloadsService} from '../../services/api/offloads.service';
import {IOption} from 'ng-select';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StatusesService} from '../../services/api/statuses.service';
import {DriversService} from '../../services/api/drivers.service';
import {OrderObj} from '../../models/order.model';
import {Unload} from '../../models/unload.model';
import {Pickup} from '../../models/pickup.model';
import {EditUnloadModalComponent} from '../edit-unload-modal/edit-unload-modal.component';
import {EditPickuprModalComponent} from '../edit-pickup-modal/edit-pickup-modal.component';
import {OrdersService} from '../../services/api/orders.service';
import {PrioritiesService} from '../../services/api/priorities.service';
import {AuthService} from '../../services/auth/auth.service';
import {RejectPickupModalComponent} from '../reject-pickup-modal/reject-pickup-modal.component';
import {RejectReason} from '../../models/reject-reason.model';
import {DeletePickupModalComponent} from '../delete-pickup-modal/delete-pickup-modal.component';
import {AuditLogService} from '../../services/api/audit-log.service';
import * as moment from 'moment';

@Component({
    selector: 'app-modal-component',
    templateUrl: './edit-order-modal.component.html',
    styleUrls: ['./edit-order-modal.component.scss',
    ],
})
export class EditOrderModalComponent implements OnInit {

    title: string;
    closeBtnName: string;
    list: any[] = [];
    active: boolean;
    onClose: Subject<boolean>;
    data: any[] = [];
    template = '';
    offloads: Array<IOption> = [];
    defaultOffload = '';
    statuses: Array<IOption> = [];
    defaultStatus = '2';
    drivers: Array<IOption> = [];
    defaultDriver = '1';
    priorities: Array<IOption> = [];
    defaultPriority = '1';
    dispatchOrderForm: FormGroup;
    order: OrderObj;
    orders: OrderObj[];
    activeTab = 'Order';
    selectedOrder;
    originalOrders: OrderObj[];
    saved: boolean;
    globalStatus = false;
    statusId = '';
    priorityId = '';
    readonly = false;
    auditLog = {
        entries: []
    };

    constructor(public bsModalRef: BsModalRef,
                public offloadService: OffloadsService,
                public statusesService: StatusesService,
                public driversService: DriversService,
                public modalService: BsModalService,
                public orderService: OrdersService,
                public prioritiesService: PrioritiesService,
                public auth: AuthService,
                public auditLogService: AuditLogService) {
        this.onClose = new Subject();
    }

    setTab(tab) {
        this.activeTab = tab;
    }

    ngOnInit() {
        // this.list.push('PROFIT!!!');
        this.statusId = this.order.status_id.toString();
        this.priorityId = this.order.priority_id.toString();
        this.originalOrders = JSON.parse(JSON.stringify(this.orders));
        this.prioritiesService.getPriorities().subscribe(data => {
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].name,
                    disabled: false
                });
            }
            this.priorities = options;
        });
        this.offloadService.getOffloads().subscribe(data => {
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].name,
                    disabled: false
                });
            }
            this.offloads = options;
        });

        this.statusesService.getStatuses().subscribe(data => {
            const count = data.length;
            const options = [];
            const ignoredStatuses = ['NEW', 'RECURRING', 'UNLOADING', 'REASSIGNED'];
            for (let i = 0; i < count; i++) {
                if (this.auth.hasPermission('correct orders') || ignoredStatuses.indexOf(data[i].name) === -1) {
                    options.push({
                        value: data[i].id.toString(),
                        label: data[i].name,
                        disabled: false
                    });
                }
            }
            this.statuses = options;
        });

        this.driversService.getDrivers().subscribe(response => {
            const count = response.data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: response.data[i].id.toString(),
                    label: response.data[i].driver_name,
                    disabled: false
                });
            }
            this.drivers = options;
        });

        this.getAuditLogs();
    }

    public async onConfirm(): Promise<void> {
        if (this.saved) {
            return;
        }
        this.saved = true;
        this.order.status_id = parseInt(this.statusId, 10);
        this.order.priority_id = parseInt(this.priorityId, 10);

        if (this.globalStatus) {
            const globalStatusId = this.orders[0].status_id;
            for (let i = 0; i < this.orders.length; i++) {
                this.orders[i].status_id = globalStatusId;
            }
        }

        if (this.auth.hasPermission('correct orders')) {
            await this.orderService.correction(this.orders);
        } else if (this.auth.hasPermission('edit orders')) {
            await this.orderService.edit(this.orders);
        }

        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.orders = this.originalOrders;
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this.bsModalRef.hide();
    }

    public orderChanged(event) {
        const index = this.orders.findIndex((order) => {
            return order.id === parseInt(this.selectedOrder, 10);
        });

        this.order = this.orders[index];
        this.title = 'Edit Order: ' + this.order.id;
        if (this.auth.hasPermission('correct orders')) {
            this.readonly = this.order.status_name === 'HAULED';
        }
        this.getAuditLogs();
    }

    public editUnload(index, unload: Unload) {
        if (this.auth.hasPermission('correct orders')) {
            const initialState = {
                unload: JSON.parse(JSON.stringify(unload)),
            };
            const bsModalRef = this.modalService.show(EditUnloadModalComponent, {class: 'modal-lg', initialState: initialState});
            bsModalRef.content.closeBtnName = 'Close';
            bsModalRef.content.title = 'Edit Unload: ' + unload.ticket_number + '';
            bsModalRef.content.template = '';

            bsModalRef.content.onClose.subscribe(result => {
                if (result === true) {
                    this.order.unloads[index] = bsModalRef.content.unload;
                    // They pressed submit
                } else if (result === false) {
                    // They pressed No
                } else {
                    // Modal was just hidden
                }
            });
        }
    }

    openNewTab(url) {
        window.open(url, '_blank');
    }

    printTicket(ticket) {
        const url = '#/ticket/' + ticket;
        this.openNewTab(url);
    }

    printUnloadTicket(ticket) {
        const url = '#/ticket/unload/' + ticket;
        this.openNewTab(url);
    }

    public deletePickup(index, pickup: Pickup) {
        if (this.auth.hasPermission('correct orders')) {
            const initialState = {
                pickup: JSON.parse(JSON.stringify(pickup))
            };
            const bsModalRef = this.modalService.show(DeletePickupModalComponent, {class: 'modal-lg', initialState: initialState});
            bsModalRef.content.closeBtnName = 'Close';
            bsModalRef.content.title = 'Delete Pickup: ' + pickup.ticket_number + '';
            bsModalRef.content.template = '';

            bsModalRef.content.onClose.subscribe(result => {
                if (result === true) {
                    this.orderService.delete(pickup.id).then((response) => {
                        this.order.pickups = this.order.pickups.filter((thisPickup) => {
                            return thisPickup.id !== pickup.id;
                        });
                    });
                    // They pressed submit
                } else if (result === false) {
                    // They pressed No
                } else {
                    // Modal was just hidden
                }
            });
        }
    }

    public rejectPickup(index, pickup: Pickup) {
        let reject: RejectReason;
        if (this.auth.hasPermission('correct orders')) {
            const initialState = {
                pickup: JSON.parse(JSON.stringify(pickup))
            };
            const bsModalRef = this.modalService.show(RejectPickupModalComponent, {class: 'modal-lg', initialState: initialState});
            bsModalRef.content.closeBtnName = 'Close';
            bsModalRef.content.title = 'Reject Pickup: ' + pickup.ticket_number + '';
            bsModalRef.content.template = '';

            reject = bsModalRef.content.reject;
            bsModalRef.content.onClose.subscribe(result => {
                if (result === true) {
                    this.orderService.reject(pickup.id, reject).then((response) => {
                        if (response.data.is_rejected) {
                            this.statusId = '6';
                            this.order.status_id = 6;
                        }
                    });
                    // They pressed submit
                } else if (result === false) {
                    // They pressed No
                } else {
                    // Modal was just hidden
                }
            });
        }
    }

    public editPickup(index, pickup: Pickup) {
        if (this.auth.hasPermission('correct orders')) {
            const initialState = {
                pickup: JSON.parse(JSON.stringify(pickup))
            };
            const bsModalRef = this.modalService.show(EditPickuprModalComponent, {class: 'modal-lg', initialState: initialState});
            bsModalRef.content.closeBtnName = 'Close';
            bsModalRef.content.title = 'Edit Pickup: ' + pickup.ticket_number + '';
            bsModalRef.content.template = '';

            bsModalRef.content.onClose.subscribe(result => {
                if (result === true) {
                    this.order.pickups[index] = bsModalRef.content.pickup;
                    // They pressed submit
                } else if (result === false) {
                    // They pressed No
                } else {
                    // Modal was just hidden
                }
            });
        }
    }

    getAuditLogs() {
        this.auditLog.entries = [];
        if (this.auth.hasPermission('correct orders')) {
            this.auditLogService.getAuditLog({Order: this.order.id}).subscribe((response) => {
                response.data.entries.map((entry) => {
                    entry.created_at_local = moment.utc(entry.created_at).local().format('Do, MMM YYYY h:mm a')
                });
                this.auditLog.entries = response.data.entries;
            });
        }
    }
}
